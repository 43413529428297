import React from "react";

import { Nav, Navbar } from "react-bootstrap";

import logo from "../assets/logos/2.svg";
export const Holder = () => {
  return (
    <div className="holder-container">
      <img
        alt="qsdq"
        height="150"
        className="d-inline-block align-top navbar-logo"
        src={logo}
      />
      <div className="text-container">
        <h2>SITIO EN CONSTRUCCIÓN</h2>
        <h4>Te invitamos a enviar tu consulta a contacto@chaine.ar</h4>

        <h6>Sepa disculpar las molestias</h6>
      </div>
    </div>
  );
};
